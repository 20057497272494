<template>
            <a class="btn btn-outline-success ml-3" :href="url">
                <i class="fa fa-shopping-cart"></i>
                <span class="badge badge-light">{{ item_count }}</span>
            </a>
</template>

<script>
    export default {
        props: ['url','item_count'],
        mounted() {
            console.log('Component mounted.')
        },
    }
</script>

# <cart-component url="{{ route('cart.index') }}" item_count="{{ Request::session()->has('cart_count') ? Request::session()->get('cart_count') : 0 }}"></cart-component>